<template>
	<v-app>

		<v-main class="background">
			<v-container>
				<v-row>
					<v-col cols="12">
						<v-img lazy-src="~@/assets/logowhite.png" max-height="200" max-width="200"
							src="~@/assets/logowhite.png"></v-img>
					</v-col>
					<v-col cols="12" md="6" class="mt-12">
						<v-chip color="secondary" outlined pill class="my-8"><strong>PRIVATE SALE HAS
								ENDED!</strong></v-chip>
						<h1 class="display-3 mb-5">Crypto-Strikers $MAMOT Private Sale</h1>
						<h2>Breathing New Life into Burned Tokens</h2>
						<v-btn color="light" class="mt-5 mr-5" large target="_blank" tile outlined
							href="https://crypto-strikers.com/wp-content/uploads/Crypto-Striker-Whitepaper-Oktober-v1.pdf">Whitepaper</v-btn>
						<v-btn color="light" class="mt-5 " large target="_blank" tile outlined
							href="https://cryptostrikers.gitbook.io/v1/">Gitbook</v-btn>

					</v-col>
					<v-col cols="0" md="1"></v-col>
					<v-col cols="12" md="5">
						<v-card class="mb-12" color="light">
							<v-card-text>

								<v-row>
									<v-col cols="12">
										<v-img lazy-src="~@/assets/logowhite.png" max-height="150" max-width="150"
											src="~@/assets/logowhite.png"></v-img>
									</v-col>
									<v-col cols="12">
										<h2>$MAMOT PRIVATE SALE</h2>
									</v-col>
									<v-col cols="3">
										<p class="text-primary text-left">Soft cap</p>
										<p class="text-secondary text-left"><strong>{{ softcap }} {{ symbol }}</strong></p>
									</v-col>
									<v-col cols="6"></v-col>
									<v-col cols="3">
										<p class="text-primary text-left">Hard cap</p>
										<p class="text-secondary text-left"><strong>{{ hardcap }} {{ symbol }}</strong></p>
									</v-col>
								</v-row>
								<p><span class="text-gray">Progress: </span><strong>{{ Math.ceil(progress) }}%</strong></p>
								<v-progress-linear height="20" :value="progress" striped color="primary">
								</v-progress-linear>


								<div class="d-flex justify-space-between mt-3">
									<p>{{ raised }} {{ symbol }} raised</p>
									<p class="text-right">{{ hardcap }} {{ symbol }}</p>
								</div>
								<v-btn color="primary" block class="mb-5" disabled x-large to="/buy">
									Join private sale
								</v-btn>
								<v-divider></v-divider>
								<h4 class="text-center my-3">Private sale ended</h4>
								<!-- <privateSaleCountdown /> -->
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
// import axios from 'axios'


export default {

	data: () => ({

		progress: 10,
		raised: 25.27728,
		symbol: "ETH",
		hardcap: 35,
		softcap: 15
	}),

	mounted() {
		this.progress = this.raised / this.hardcap * 100;

	},
}
</script>
<style>
.v-btn.primary {
	font-weight: 900 !important;
	font-family: 'Archivo', sans-serif !important;
	padding: 10px 20px !important;
	color: rgba(33, 33, 33, 1) !important;
}
</style>
